import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Card,
  Grid,
  Hidden,
  Container,
  CardHeader,
  CardContent,
} from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

export default (props) => {
  const classes = useStyles()
  return (
    <div
      className={classes.header}
    >
      <Typography
        variant="h4"
        className={classes.title}
      >
        {props.title}
      </Typography>
      <Typography
        variant="body1"
        className={classes.subtitle}
      >
        {props.subtitle}
      </Typography>
      {props.action && (
        <div className={classes.action}>
          {props.action}
        </div>
      )}
      <Card className={classes.card}>
        <Grid
          item
          className={classes.grid}
        >
          <Grid
            item
            className={classes.gridInstructor}
          >
            <Typography
              variant="body1"
              className={classes.instructor}
            >
              {props.instructor}
            </Typography>
            <div className={classes.imgInstructor}>
              {props.img}
            </div>
            <Typography
              variant="h6"
              className={classes.instructorName}
            >
              {props.instructorName}
            </Typography>
            <Typography
              variant="body2"
            >
              {props.instructorPosition}
            </Typography>
          </Grid>
          <Grid className={classes.gridCard}>
            {props.cards && (
              props.cards.map((item) => {
                return (
                  <div
                    key={item.id}
                  >
                    <Hidden
                      xsDown
                    >
                      <Grid
                        className={classes.gridCardOutter}
                      >
                        <Grid
                          className={classes.gridCardInner}
                        >
                          <Card
                            className={classNames(classes.cardDetailInfo, {
                              [classes.cardHeaderActive]: item.active,
                            })}
                          >
                            <Typography
                              variant="subtitle1"
                            >
                              {item.detailDate}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                            >
                              {item.detailTime}
                            </Typography>
                          </Card>
                          <Container
                            className={classes.containerDetailInfo}
                          >
                            <Typography
                              variant="body1"
                              className={classes.titleDetailInfo}
                            >
                              {item.detailTitle}
                            </Typography>
                            <Typography
                              variant="body2"
                            >
                              {item.detailSubtitle}
                            </Typography>
                          </Container>
                        </Grid>
                      </Grid>
                    </Hidden>
                    <Hidden
                      smUp
                    >
                      <Card
                        className={classNames(classes.cardDetail, {
                          [classes.cardActive]: item.active,
                        })}
                      >
                        <CardHeader
                          titleTypographyProps={{ variant: 'body1' }}
                          title={item.detailDate}
                          subheader={item.detailTime}
                          className={classNames(classes.cardHeader, {
                            [classes.cardHeaderActive]: item.active,
                          })}
                          classes={{
                            subheader: classes.subHeader,
                          }}
                        />
                        <CardContent>
                          <Typography
                            variant="body1"
                            style={{ fontWeight: 'bold' }}
                          >
                            {item.detailTitle}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                          >
                            {item.detailSubtitle}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Hidden>
                  </div>
                )
              })
            )}
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}
