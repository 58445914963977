import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
} from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

export default (props) => {
  const classes = useStyles()
  return (
    <Container
      maxWidth="lg"
      className={classes.wrapper}
    >
      {props.children}
    </Container>
  )
}
