export default (theme) => {
  return {
    code: {
      margin: '0.5rem 0',
      padding: '0.8rem',
      overflow: 'auto',
      fontSize: '0.9rem',
      lineHeight: '1.45',
      backgroundColor: '#f6f8fa',
      borderRadius: '0.5rem',
      overflowWrap: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'pre-wrap',
      [theme.breakpoints.down('xs')]: {
        padding: '0.5rem',
        fontSize: '0.75rem',
      },
    },
  }
}
