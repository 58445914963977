export default (theme) => {
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      padding: '2rem',
      width: '90%',

      [theme.breakpoints.down('xs')]: {
        padding: '1rem',
      },
    },
  }
}
