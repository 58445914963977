import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import styles from './styles'

const useStyles = makeStyles(styles)

export default (props) => {
  const classes = useStyles()
  return (
    <div
      className={classes.embed}
      style={props.style}
    >
      <iframe
        title={props.title}
        src={props.source}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  )
}
