import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import styles from './styles'

const useStyles = makeStyles(styles)

export default (props) => {
  const classes = useStyles()
  return (
    <pre className={classes.code}>
      {props.children}
    </pre>
  )
}
