import React from 'react'
import { graphql } from 'gatsby'
import {
  Button,
  Typography,
  Grid,
} from '@material-ui/core'

import Page from '~/src/components/page'
import EventHero from '~/src/components/events/hero'
import EventContent from '~/src/components/events/content'
import Seo from '~/src/components/seo'
import Link from '~/src/components/link'
import Code from '~/src/components/code'
import EmbedYoutube from '~/src/components/embed/youtube'
import BackgroundServices from '~/src/components/background/services'

const webinarJavascript = [
  {
    id: 1,
    detailDate: '16 Agustus 2020',
    detailTime: '3pm - 4pm',
    detailTitle: 'Full-stack web development',
    detailSubtitle: 'React website dan NodeJS server',
    active: true,
  },
  {
    id: 2,
    detailDate: '23 Agustus 2020',
    detailTime: '3pm - 4pm',
    detailTitle: 'Praktik industri web development',
    detailSubtitle: 'React component design, database migrations, etc',
    active: false,
  },
  {
    id: 3,
    detailDate: '30 Agustus 2020',
    detailTime: '3pm - 4pm',
    detailTitle: 'Memperluas fitur dari project template',
    detailSubtitle: 'Custom component, fitur baru, dan plan ke depan',
    active: false,
  },
]

const EventPage = ({ data }) => {
  return (
    <Page>
      <Seo
        site={data.site}
        title="Webinar: Javascript full stack development React & Node.JS in Augustus 2020"
        ogTitle="Webinar by MAJIN: Javascript full stack development React & Node.JS in Augustus 2020"
        description="Webinar gratis untuk belajar Javascript web development dengan React dan Node.JS untuk mendukung perkembangan teknologi dan design, diselenggarakan oleh team MAJIN software development studio di Padang, Indonesia"
        canonical="https://majin.land/events/webinar-javascript-fullstack-2020-august"
        ogImage="https://majin.land/images/webinar_2020_aug.jpg"
      />
      <BackgroundServices />
      <EventHero
        title="Javascript full stack development React & Node.JS"
        subtitle="Webinar untuk kebutuhan industri startup"
        action={(
          <Button
            variant="contained"
            color="primary"
            size="large"
            title="Daftar di Zoom"
            href="https://zoom.us/webinar/register/WN_ivSiYr6gQDeIebMgCywPhg"
            target="_blank"
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '0.875rem',
              fontWeight: 'bold',
            }}
          >
            Daftar di Zoom
          </Button>
        )}
        instructor="Instructor"
        img={(
          <img
            src="/images/stan.jpg"
            alt="Instructor"
            style={{
              width: '160px',
              height: '160px',
              margin: '1rem auto',
              borderRadius: '20rem',
            }}
            loading="lazy"
          />
        )}
        instructorName="Stanley"
        instructorPosition="Tech lead at Majin"
        cards={(webinarJavascript)}
      />
      <EventContent>
        <Typography paragraph component="div">
          Contoh dari hasil karya kami yang sudah berhasil launch di Padang: <Link to="https://rakenid.com/">Raken</Link>.
          Raken ini di develop oleh team Majin dari server, web & desktop management platform dan mobile application semua di buat dengan Javascript.
          Please bantu download dan check it out kalo tertarik.
        </Typography>
        <Typography paragraph component="div">
          Untuk seminar ini kita akan menggunakan source code dari project <Link to="https://auster.majin.land/">Auster</Link> (<Link to="https://github.com/majin-land/auster">github</Link>)
          Majin develop project Auster ini untuk menyebarkan ide pengelolaan uang pribadi dengan menyediakan system gratis yg gampang dipakai oleh masyarakat.
          Dan disamping itu, kita ingin menggunakan source code project ini sebagai contoh pengajaran web application development.
        </Typography>
        <img
          src="/images/auster_add_new_transaction_record.png"
          alt="Auster add new transaction record"
          style={{ width: '80%', margin: '0 auto' }}
          loading="lazy"
        />
        <Grid
          id="update-17-agustus-2020"
          container
          direction="column"
          style={{ margin: '2rem 0' }}
        >
          <Typography
            variant="h5"
            style={{ fontWeight: 'bold' }}
          >
            Update 17 Agustus 2020
          </Typography>
          <EmbedYoutube
            title="React + NodeJS + PostgreSQL full stack web application 2020"
            source="https://www.youtube.com/embed/pAGz1vrkvoc"
            style={{ margin: '1.5rem 0' }}
          />
          <Typography paragraph component="div">
            Bisa revise apa yang kita sudah diskusi di seminar pertama dengan nonton ulang rekaman yang kita sudah upload di youtube ini.
            Selain itu mungkin topic yang kita bahas untuk seminar ini cukup cepat dan lebih mendalam,
            bisa coba pelajari dulu dasar2 Javascript dan kalau mau mulai dengan example project yang lebih gampang,
            boleh coba git clone project kami yang ini <Link to="https://github.com/majin-land/todo-react">Todo React example project</Link>.
          </Typography>
        </Grid>
        <Grid
          container
          direction="column"
          style={{ marginBottom: '1rem' }}
        >
          <Typography variant="h5" style={{ marginBottom: '1.5rem', fontWeight: 'bold' }}>
            What to prepare
          </Typography>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Setup NodeJS
          </Typography>
          <Typography paragraph>
            Download dan install NodeJS <Link to="https://nodejs.org/en/download/">https://nodejs.org/en/download/</Link>
          </Typography>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Install Yarn
          </Typography>
          <Typography paragraph>
            Pilih operating system dan ikuti instruksi di page ini <Link to="https://classic.yarnpkg.com/en/docs/install">https://classic.yarnpkg.com/en/docs/install</Link>
          </Typography>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Install dan setup Postgres database
          </Typography>
          <Typography paragraph>
            Download sesuai operating system computer disini <Link to="https://www.enterprisedb.com/downloads/postgres-postgresql-downloads">https://www.enterprisedb.com/downloads/postgres-postgresql-downloads</Link> (kalau Windows computer, kemungkinan besar perlu pilih yang Windows x86-64)
          </Typography>
          <Typography paragraph component="div">
            Sesudah berhasil install, buka <Link to="https://support.apple.com/en-gb/guide/terminal/apd5265185d-f365-44cb-8b09-71a064a42125/mac">Terminal</Link> (untuk mac)
            atau <Link to="https://www.c3scripts.com/tutorials/msdos/open-window.html">MS DOS</Link> (untuk windows)
            bisa connect ke postgres seperti ini:
            <Code>
              psql -h localhost -p 5432 -U postgres -d postgres
            </Code>
          </Typography>
          <Typography paragraph component="div">
            Sesudah itu kita bakal create database baru dengan nama 'auster', dengan command: 'create database auster;'
            <Code>
              postgres=# create database auster;<br />
              CREATE DATABASE
            </Code>
          </Typography>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Install dan setup PgAdmin
          </Typography>
          <Typography paragraph>
            Ini supaya ada GUI untuk gampang check data di database Postgres kita <Link to="https://www.pgadmin.org/download/">https://www.pgadmin.org/download/</Link>
          </Typography>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Install code text editor
          </Typography>
          <Typography paragraph>
            Untuk kelas ini bakal pakai text editor ini <Link to="https://code.visualstudio.com/download">Visual Studio Code (VSCode)</Link> (recommended)
            text editor yang cukup ringan dan gampang di customize
          </Typography>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Install Git
          </Typography>
          <Typography paragraph>
            For <Link to="https://git-scm.com/download/win">Windows</Link> dan <Link to="https://git-scm.com/download/mac">Mac</Link>
          </Typography>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Setup Auster project
          </Typography>
          <Typography paragraph component="div">
            Git clone project auster di <Link to="https://github.com/majin-land/auster">https://github.com/majin-land/auster</Link>.
            Untuk git clone, buka Terminal atau MS DOS dan pergi ke folder yang kamu ingin kan and run:
            <Code>
              git clone git@github.com:majin-land/auster.git
            </Code>
            Ini akan nge copy semua project file yang ada di github repository itu ke computer kamu.
            Dan ketika git clone nya selesai akan nampak folder nama 'auster' dan bisa masuk ke folder itu
            <Code>
              cd auster
            </Code>
            Kita bakal buat file baru .env dari .env.sample
            <Code>
              cp .env.sample .env
            </Code>
            dan di dalam file .env ini adalah informasi configuration untuk application kita
            <Code>
              NODE_ENV=development<br />
              <br />
              # CLIENT<br />
              API_SERVER_SCHEME=http<br />
              API_SERVER_HOST=localhost<br />
              API_SERVER_PORT=4010<br />
              ASSET_URL=https://auster.majin.land<br />
              CLIENT_PORT=4020 # DEV ONLY<br />
              <br />
              # SERVER<br />
              SERVER_PORT=4010<br />
              DB_USER=postgres<br />
              DB_PASS=<br />
              DB_DATABASE=auster<br />
              DB_HOST=localhost<br />
              DB_PORT=5432<br />
            </Code>
          </Typography>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Setup and run project
          </Typography>
          <Typography paragraph component="div">
            Install project code dependency
            <Code>
              yarn install
            </Code>
          </Typography>
          <Typography paragraph component="div">
            Start application
            <Code>
              yarn run dev
            </Code>
          </Typography>
          <Typography paragraph component="div">
            Command ini akan memulai Node server dan juga React website sekaligus.
            Untuk test Node server nya berhasil jalan bisa test berikut:
            <Code>
              1. http://localhost:4010/public/time<br />
              will return server time<br /><br />
              2. http://localhost:4010/public/hello/majin<br />
              will return 'Hello, Majin!'
            </Code>
            dan untuk React website nya bakal bisa check di http://localhost:4010
          </Typography>
          <Typography paragraph>
            Kalau ada masalah dengan setup ini silakan hubungi kami di dev@majin.land
          </Typography>
          <Typography paragraph>
            And now you are ready for the webinar!
          </Typography>
        </Grid>
        <Grid container style={{ marginBottom: '5rem' }}>
          <Typography variant="h5" style={{ marginBottom: '1.5rem', fontWeight: 'bold' }}>
            What and why
          </Typography>
          <Typography paragraph>
            Webinar ini diadakan dengan tujuan memberikan ilmu2 web application development yang up to date dengan standard industri
            dan memajukan kemampuan tenaga kerja kita dalam bidang software development.
          </Typography>
          <Typography paragraph>
            Ada 3 kelas webinar yang akan diadakan lewat <Link to="https://zoom.us/download" target="_blank">Zoom</Link> (download client nya)
            untuk merangkup pelajaran untuk Javascript full stack development ini.
            Untuk di setiap kelas kita akan membahas konsep-konsep dan contoh penggunaan langsung
            di project code yang sudah disediakan untuk kelas ini.
          </Typography>
          <Typography paragraph>
            Untuk hasil maksimal, diharapkan untuk menghadiri 3 kelas yang kami siapkan.
            Dan mempersiapkan dulu komputer masing2 dengan setup instruction, software dan tugas2 yang diberikan untuk perparation setiap kelas.
            Ini supaya bisa mengikuti proses coding dan penggunaan example project nya pada saat webinar ini.
          </Typography>
        </Grid>
      </EventContent>
    </Page>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`

export default EventPage
