export default (theme) => {
  return {
    bgLeft: {
      position: 'absolute',
      top: '2rem',
      width: '100%',
      height: '600px',
      backgroundSize: 'auto',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '20% 0%',
      background: 'url(/images/bg-service-left.svg)',
      zIndex: -1,

      [theme.breakpoints.down('md')]: {
        backgroundSize: '12.5rem 10.6rem',
        backgroundPosition: '5% 0%',
        top: '1rem',
      },
      [theme.breakpoints.down('sm')]: {
        backgroundSize: '9.5rem 8.6rem',
        backgroundPosition: '3% 0%',
        top: '1rem',
      },
      [theme.breakpoints.down('xs')]: {
        backgroundSize: '5.5rem 4.6rem',
        top: '0.5rem',
      },
    },
    bgRight: {
      position: 'absolute',
      top: '1rem',
      width: '100%',
      height: '600px',
      backgroundSize: 'auto',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '80% 0%',
      background: 'url(/images/bg-service-right.svg)',
      zIndex: -1,

      [theme.breakpoints.down('md')]: {
        backgroundSize: '11.5rem 9.6rem',
        backgroundPosition: '95% 0%',
        top: '1rem',
      },
      [theme.breakpoints.down('sm')]: {
        backgroundSize: '8.8rem 8rem',
        backgroundPosition: '95% 0%',
        top: '0.5rem',
      },
      [theme.breakpoints.down('xs')]: {
        backgroundSize: '4.8rem 4rem',
        backgroundPosition: '98% 0%',
        top: '0.5rem',
      },
    },
  }
}
