export default (theme) => {
  const {
    breakpoints,
    color,
  } = theme

  return {
    header: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: '2rem',
      backgroundColor: color.opaqueGrey,
      [theme.breakpoints.down('xs')]: {
        padding: '1rem',
      },
    },
    title: {
      marginBottom: '1rem',
      textAlign: 'center',
      maxWidth: '40rem',
      color: theme.color.secondary,
      fontWeight: 'bold',

      [theme.breakpoints.down('xs')]: {
        maxWidth: '20rem',
        fontSize: '2rem',
      },
    },
    subtitle: {
      fontSize: '1.2rem',

      [theme.breakpoints.down('sm')]: {
        fontSize: '0.9rem',
      },
    },
    action: {
      marginTop: '1rem',
    },
    card: {
      marginTop: '5rem',
      maxWidth: '977px',
      width: '100%',
      borderRadius: '1rem',
      [breakpoints.down('sm')]: {
        maxHeight: 'none',
      },
    },
    grid: {
      display: 'flex',
      flexDirection: 'row',
      padding: '1.5rem 2rem',
      [breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    instructor: {
      color: theme.color.monsoon,
      fontWeight: 'bold',
    },
    gridInstructor: {
      textAlign: 'center',
      marginRight: '2rem',
      padding: '0 1.5rem',
      flex: '3',
    },
    imgInstructor: {
      [breakpoints.down('sm')]: {
        width: '50%',
        margin: 'auto',
      },
    },
    instructorName: {
      fontWeight: 'bold',
    },
    gridCard: {
      flex: '13',
      margin: 'auto',
    },
    gridCardOutter: {
      display: 'flex',
      flexDirection: 'column',
      margin: '1.5rem 0  1.5rem 1rem',
    },
    gridCardInner: {
      display: 'flex',
      flex: '100%',
      flexDirection: 'row',
    },
    cardDetailInfo: {
      background: theme.color.primary,
      color: theme.color.white,
      maxWidth: '200px',
      width: '100%',
      maxHeight: '60px',
      height: '100%',
      textAlign: 'center',
      padding: 'auto',
    },
    cardDetail: {
      marginTop: '2rem',
      border: `1px solid ${theme.color.primary}`,
      textAlign: 'center',
    },
    cardActive: {
      border: `1px solid ${theme.color.lavenderGray}`,
    },
    cardHeader: {
      background: theme.color.primary,
      color: theme.color.white,
    },
    cardHeaderActive: {
      background: theme.color.lavenderGray,
    },
    subHeader: {
      color: theme.color.white,
    },
    containerDetailInfo: {
      textAlign: 'left',
      margin: 'auto 0',
    },
    titleDetailInfo: {
      fontWeight: 'bold',
    },
  }
}
